body {
    margin: 0;
    font-family: 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1em;
}

h1 {
    font-size: 2.5em;
    font-weight: 900;
}

button {
    height: 2.5em;
    font-size: 1em;
    background-color: black;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

input {
    font-size: 1em;
    width: 200px;
    height: 30px;
    background: white;
    border: 1px solid black;
    border-radius: 5px;
    margin: 5px;
    padding: 5px;
    font-family: 'Inter', sans-serif;
}

img {
    background-color: #ffffff;
    background-image: linear-gradient(315deg, #ffffff 0%, #d7e1ec 74%);
}

.full {
    flex: 1;
}

.flex {
    display: flex;
}

.center {
    justify-content: center;
    align-items: center;
}

.center-self {
    align-self: center;
}

.vertical {
    flex-direction: column;
}

.column {
    flex-direction: column;
}

.wrap {
    flex-wrap: wrap;
}

.svg-default {
    width: 20px;
    height: 20px;
    fill: black;
}

.image-button {
    width: 20px;
    margin: 20px;
    cursor: pointer;
}

.image-button:hover {
    fill: grey;
}

.title {
    /* margin-left: 25px; */
    /* margin-top: 0px; */
    text-align: center;
    margin-block-end: 5px;
    font-weight: 500;
}

.box {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

.round {
    border-radius: 5px;
}

.hidden {
    display: none;
}

::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .5);
    box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.bold {
    font-weight: bold;
}

.apart {
    justify-content: space-around;
}