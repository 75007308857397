.slide {
  transition: .50s;
}

.in {
  left: 0px !important;
}

.out {
  left: -310px !important;
}
